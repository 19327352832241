var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[(_vm.loading)?_c('Loader',{staticClass:"w-full lg:w-main-container lg:h-sidebar",class:_vm.getHeightOfScreenWidth(1)}):_c('div',{staticClass:"grid grid-cols-2 gap-4 w-full"},[(_vm.currentCategory && (_vm.currentCategory.slug === 'news' || _vm.$route.query.search || _vm.currentCategory.slug === 'faq' || _vm.currentCategory.slug === 'hints') && _vm.postsData.length > 0)?_vm._l((_vm.postsData),function(post,index){return _c('div',{key:post.id,staticClass:"lg:rounded-lg overflow-hidden row-span-1",class:{
            'col-span-2' : _vm.isMobile || index === 0 && _vm.pagination.currentPage===1 && (_vm.currentCategory.slug === 'news' || _vm.currentCategory.onePost) && !_vm.$route.query.search,
            'lg:col-span-1': !_vm.isMobile && (index > 0 || _vm.pagination.currentPage > 1) && _vm.$route.query.search,
            'lg:row-span-2': !_vm.isMobile && index === 1 && _vm.pagination.currentPage === 1 && _vm.currentCategory.slug === 'news' && !_vm.$route.query.search,
          }},[_c('div',{staticClass:"lg:rounded-lg overflow-hidden lg:border border-t border-b border-grayed"},[(index === 0 && _vm.pagination.currentPage===1 && !_vm.isMobile && !_vm.$route.query.search)?[_c('PostCard',{attrs:{"post-data":post,"small":_vm.currentCategory.slug !== 'news' && !_vm.currentCategory.onePost},on:{"post-clicked":_vm.showPostModal}})]:(index === 1 && _vm.pagination.currentPage===1 && !_vm.isMobile && !_vm.$route.query.search)?[_c('PostCard',{attrs:{"narrow":true,"second-post":_vm.currentCategory.slug == 'news',"small":_vm.currentCategory.slug != 'news',"post-data":post},on:{"post-clicked":_vm.showPostModal}})]:[_c('PostCard',{attrs:{"small":true,"post-data":post},on:{"post-clicked":_vm.showPostModal}})]],2)])}):(_vm.currentCategory && (
					_vm.currentCategory.slug === 'rules'
        || _vm.currentCategory.slug === 'privacy'
        || _vm.currentCategory.slug === 'contact'
        )
      )?[(_vm.postsData[0])?_c('div',{staticClass:"rounded-lg overflow-hidden col-span-2 lg:border border-t border-b border-grayed"},[_c('PostShow',{attrs:{"slug":_vm.postsData[0].slug}})],1):_vm._e()]:[_c('div',{staticClass:"rounded-lg overflow-hidden col-span-2 lg:border border-t border-b border-grayed"},[_c('PostCard',{attrs:{"empty-post":"","single-post":"","post-data":{
							image: _vm.emptyPost.image,
							title: _vm.emptyPost.title,
						}}})],1)]],2),_c('div',{staticClass:"mt-3",class:{'hidden': _vm.loading}},[_c('div',{staticClass:"flex-1 flex items-center justify-end"},[(_vm.postsData.length > 0 && _vm.pagination.total > 6)?_c('div',[_c('t-pagination',{attrs:{"variant":"posts","total-items":_vm.pagination.total,"per-page":_vm.pagination.perPage,"limit":6,"disabled":_vm.loadingPosts},on:{"change":_vm.next},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])]),(_vm.isShowPostModal)?_c('Modal',{attrs:{"hide-footer":"","variant":"post","show-modal":_vm.isShowPostModal},on:{"clicked":function($event){_vm.isShowPostModal = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('PostShow',{attrs:{"slug":_vm.chosenPostSlug}})]},proxy:true}],null,false,1104083954)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }